<template>
    <Teleport to="body">
        <MediaOverlay
            :modalId="`v-${overlay?.id}`"
            :video="overlay?.value?.video"
            :autoplay="overlay?.value?.autoplay"
        />
    </Teleport>

    <div class="row spacer-navigation-no-gap spacer-50-40">
        <div class="col-12">
            <MediaVideo
                v-if="teaser?.type == 'wagtail'"
                class="header__video"
                :video="teaser?.value?.video"
                :autoplay="teaser?.value?.autoplay"
            />
            <MediaYoutube
                v-if="teaser?.type == 'youtube'"
                class="header__video"
                :video="teaser?.value?.video"
                :autoplay="teaser?.value?.autoplay"
            />
        </div>
    </div>
    <div class="row justify-content-center spacer-140-100">
        <div class="col-12 col-800-10 col-1200-8">
            <h1 class="scale-6 scale-6--bold-uppercase header__title">
                {{ page?.title }}
            </h1>
            <div class="scale-2 scale-2--lead">
                {{ page?.lead }}
            </div>
            <FormButton
                v-if="overlay?.id"
                class="l-button--secondary header__button"
                :label="t('video.view')"
                data-bs-toggle="modal"
                :data-bs-target="`#v-${overlay?.id}`"
            />
        </div>
    </div>
    <div v-if="page?.body.length > 0" class="spacer-footer">
        <CmsBody :page="page" />
    </div>
</template>

<script setup>
import { useWagtailClient } from '~/composables/useWagtailClient';
import { useI18n } from 'vue-i18n';
import { computed } from 'vue';

const { t } = useI18n();

const teaser = computed(() => page?.value?.video?.teaser);
const overlay = computed(() => page?.value?.video?.overlay);

const route = useRoute();
const wagtail = useWagtailClient();

const { data: page } = await useAsyncData('page', async () => {
    const res = await wagtail.getPageByPath(route.path);
    return res.data;
});
</script>

<style lang="scss" scoped>
@import '@/assets/scss/variables.scss';
@import '@/node_modules/bootstrap/scss/mixins';

.header {
    &__video {
        height: 60vh;
        min-height: 0;
        max-height: clamp(400px, 27.78vw + 311.11px, 800px);

        @include media-breakpoint-down(560) {
            margin: 0 $padding-container-negative;
            width: -webkit-fill-available;
        }
    }

    &__location {
        color: $color-disabled;
        margin-bottom: 10px;
    }

    &__title {
        margin-bottom: clamp(15px, 1.04vw + 11.67px, 30px);
    }

    &__button {
        margin-top: 25px;
    }
}
</style>
