<template>
    <ClientOnly>
        <Modal
            :id="modalId"
            :required="true"
            :close="true"
            :show="false"
            class="youtube-overlay"
            @show="playVideo"
            @hide="pauseVideo"
        >
            <MediaYoutube
                v-if="show"
                class="youtube-overlay__video"
                :video="video"
                :autoplay="autoplay"
            />
        </Modal>
    </ClientOnly>
</template>

<script setup>
const props = defineProps({
    modalId: {
        type: String,
        required: true,
    },
    video: {
        type: Object,
        required: true,
    },
    autoplay: {
        type: Boolean,
        required: false,
        default: false,
    },
});

const show = ref(false);

function playVideo() {
    if (!props.autoplay) {
        return;
    }

    show.value = true;
}

function pauseVideo() {
    show.value = false;
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/variables.scss';
@import '@/node_modules/bootstrap/scss/mixins';

.youtube-overlay {
    width: 100%;
    height: 100%;

    :deep(.layout__col) {
        height: 100vh;
    }

    :deep(.modal-close) {
        margin-top: 10px !important;
    }

    :deep(.modal-dialog) {
        align-items: start;

        @include media-breakpoint-down(560) {
            padding: 0 !important;
            align-items: center;
        }
    }

    :deep(.modal-content-box) {
        border-radius: 0 !important;
        padding: 0 !important;
        background-color: unset !important;
    }

    :deep(.modal-body) {
        position: relative;
        height: 100%;
        width: 100%;
        padding: 0 0 56.25% 0 !important;
        background-color: unset !important;
    }

    &__video {
        position: absolute !important;
        top: 0;
        left: 0;
        width: 100% !important;
        height: 100% !important;
    }
}
</style>
